import React from "react";
import BlogItem from "./BlogItem";
import "./styles.css";

const BlogList = ({ blogs }) => {
  let blogIndex = 0; // Initialize the index counter

  return (
    <div className="blogList-wrap">
      {blogs.map((blog) => {
        blogIndex++;
        return <BlogItem key={blog.id} blog={blog} index={blogIndex} />;
      })}
    </div>
  );
};

export default BlogList;
