import React from "react";
import "./style.css";

const CTAButton = ({ platformLogo, titleText, priceTag, appIcon, linkTo }) => {
  return (
    <a href={linkTo} className="cta-button-link">
      <div className="cta-button">
        <img
          src={platformLogo}
          alt="Platform Logo"
          className="cta-button-logo"
        />
        <div className="cta-button-content">
          <h2>{titleText}</h2>
          <p className="cta-button-price-tag">{priceTag}</p>
        </div>
        <img src={appIcon} alt="App Icon" className="cta-button-app-icon" />
      </div>
    </a>
  );
};

export default CTAButton;
