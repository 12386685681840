import React from "react";
import "./style.css";
import { Link } from "react-router-dom";

const LinkList = ({ links }) => {
  return (
    <div className="link-list">
      {links.map((link, index) =>
        link.logo ? (
          <Link key={index} to={link.url} className="link-item">
            <img
              src={link.logo}
              alt={`${link.text} logo`}
              className="link-logo"
            />
          </Link>
        ) : (
          <Link key={index} to={link.url} className="link-item">
            {link.text}
          </Link>
        )
      )}
    </div>
  );
};

export default LinkList;
