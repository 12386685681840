export const recentPost = {
  "id": 24,
  "site": "cda",
  "title": "Week of THE MACHINE",
  "category": "doodle",
  "subCategory": [
    "coffee",
    "sketches",
    "AI"
  ],
  "description": "We are thrilled to announce the creation of ROBO-MICHAEL, our very own AI trained on a collection of 83 of my unique drawings. These AI-generated artworks are not just another set of generic creations from the vast internet—they are imbued with my personal style, making them truly special. But the",
  "fullText": "<p>We are thrilled to announce the creation of ROBO-MICHAEL, our very own AI trained on a collection of 83 of my unique drawings. These AI-generated artworks are not just another set of generic creations from the vast internet—they are imbued with my personal style, making them truly special.</p>\r\n<p>But the excitement doesn’t stop there! While ROBO-LILY is still in development, we can't wait to add her to our roster of half-human, half-artist AI marvels. Once we finalize her training set, she will join the ranks of our creative monsters.</p>\r\n<p>What’s more, with this technology, we can now generate our art in our distinctive style in just a matter of minutes. Imagine the fun of producing countless drawings—millions, even!—without ever running out of creative steam.</p>\r\n<p>Muahahaha! No one can stop us now... from having an absolute blast with our art.</p>\r\n<p>Stay tuned for more updates and get ready to be amazed by our AI-driven creations!</p>",
  "authorName": "Lily and Michael",
  "authorAvatar": "/assets/images/lilymichael.jpg",
  "createdAt": "November 11, 2024",
  "cover": "/assets/images/CDA24-thumb.jpg",
  "duration": "13 pieces",
  "images": [
    "/assets/cda/24.11.12/01.L.10.23.24%20Aaa%20Tulip.JPG",
    "/assets/cda/24.11.12/02.M.10.25.24%20Bucks.JPG",
    "/assets/cda/24.11.12/03.M.10.25.24%20Morticia%20I'm%20Home.JPG",
    "/assets/cda/24.11.12/04.L.11.11.24%20The%20Fears%20are%20Free.JPG",
    "/assets/cda/24.11.12/05.L.10.23.24%20Haloween%202024.JPG",
    "/assets/cda/24.11.12/06.L.11.02.24%20Rose%20Snakes.JPG",
    "/assets/cda/24.11.12/07.M.11.07.24%20Racecar%20-AI-.jpg",
    "/assets/cda/24.11.12/08.M.11.07.24%20Oh%20Yeah%20-AI-.jpg",
    "/assets/cda/24.11.12/09.M.11.07.24%20Boston%20Harbor%20-AI-.jpg",
    "/assets/cda/24.11.12/10.M.11.07.24%20Measuring%20the%20Universe%20-AI-.jpg",
    "/assets/cda/24.11.12/11.M.11.07.24%20Telephone%20%20-AI-.jpg",
    "/assets/cda/24.11.12/12.M.11.07.24%20Kiss%20-AI-.jpg",
    "/assets/cda/24.11.12/13.M.11.07.24%20AI%20Coding%20-AI-.jpg"
  ],
  "ctaButtons": [
    {
      "platformLogo": "/assets/images/cta-email.png",
      "titleText": "Email Us To Buy a Print",
      "priceTag": "$30 Per Print",
      "appIcon": "/assets/images/CDALogo.png",
      "linkTo": "mailto:coffeedoodleart@gmail.com?subject=I'd like to buy one of your prints!"
    }
  ]
};