import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Chip from "../../components/common/Chip";
import EmptyList from "../../components/common/EmptyList";
import "./styles.css";
import { Link } from "react-router-dom";
import CTAButton from "../../components/Blog/CTAButton";

const Blog = () => {
  const { site, id } = useParams();
  const [blogList, setBlogList] = useState([]);
  const [blog, setBlog] = useState(null);
  const [coverUrl, setCoverUrl] = useState(null);

  useEffect(() => {
    async function fetchBlog() {
      try {
        const listModule = await import(`../../config/${site}/data.js`);
        const { blogList } = listModule;
        setBlogList(blogList);
      } catch (error) {
        console.error("Error loading list:", error);
        setBlogList([]);
      }
    }

    fetchBlog();

    let blog = blogList.find((blog) => blog.id === parseInt(id));
    if (blog) {
      setBlog(blog);
      if (blog.ctaButtons && blog.ctaButtons.length === 1) {
        setCoverUrl(blog.ctaButtons[0].linkTo);
      } else {
        setCoverUrl(`/${site}`);
      }
    }
  }, [site, id, blogList]);

  return (
    <>
      <Link className="blog-goBack" to={`/${site}`}>
        <span> &#8592;</span> <span>Go Back</span>
      </Link>
      {blog ? (
        <div className="blog-wrap">
          <header>
            <p className="blog-date">Published {blog.createdAt}</p>
            <h1>{blog.title}</h1>
            <div className="blog-subCategory">
              {blog.subCategory.map((category, i) => (
                <div key={i}>
                  <Chip label={category} />
                </div>
              ))}
            </div>
          </header>
          {blog.images && blog.images.length > 0 ? (
            <div className="image-grid">
              {blog.images.map((image, index) => (
                <div key={index} className="image-item">
                  <Link className="blog-inspect" to={`/${site}/${id}/${index}`}>
                    <img src={image} alt="CDA ART" />
                  </Link>
                </div>
              ))}
            </div>
          ) : (
            <Link className="blog-cover-link" to={coverUrl}>
              <img src={blog.cover} alt="cover" className="blog-wrap-img" />
            </Link>
          )}
          <div
            className="blog-body"
            dangerouslySetInnerHTML={{ __html: blog.fullText }}
          />
          {blog.ctaButtons ? (
            <div className="cta-buttons">
              {blog.ctaButtons.map((button, index) => (
                <CTAButton
                  key={index}
                  platformLogo={button.platformLogo}
                  titleText={button.titleText}
                  priceTag={button.priceTag}
                  appIcon={button.appIcon}
                  linkTo={button.linkTo}
                />
              ))}
            </div>
          ) : null}
        </div>
      ) : (
        <EmptyList />
      )}
    </>
  );
};

export default Blog;
