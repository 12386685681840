import React from "react";
import "./styles.css";

const currentYear = new Date().getFullYear();

const Footer = ({ hitCount }) => {
  return (
    <div className="main-footer">
      <div>
        &copy; 2014-{currentYear} Silverware Games, Inc. All rights reserved.
        Page hits: {hitCount}
      </div>
    </div>
  );
};

export default Footer;
