import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import EmptyList from "../../components/common/EmptyList";
import LinkList from "../../components/common/LinkList";
import BlogList from "../../components/Home/BlogList";
import Header from "../../components/Home/Header";
import SearchBar from "../../components/Home/SearchBar";
import BlogItem from "../../components/Home/BlogList/BlogItem";
import { recentPost } from "../../config/recentPost";
import { Link } from "react-router-dom";
/*
const links = [
  { text: "Silverware Games", url: "/swg" },
  { text: "Matchyverse", url: "/matchy" },
  { text: "Coffee Doodle Art", url: "/cda" },
  { text: "Michaelplzno", url: "/plzno" },
  { text: "Press Info", url: "/press" },
];
*/

const links = [
  {
    text: "Sporky's Blog",
    url: "/swg",
    logo: "/assets/images/silverware_games_logo.png",
  },
  {
    text: "Coffee Doodle",
    url: "/cda",
    logo: "/assets/images/cda-link3.png",
  },
  {
    text: "Matchyverse",
    url: "/matchyverse",
    logo: "/assets/images/matchyverse_link.webp",
  },
  {
    text: "Michaelplzno",
    url: "/plzno",
    logo: "/assets/images/michaelplzno-small.png",
  },
];

const defaultHeaderInfo = {
  top: "Silverware Games, Inc.",
  title: "We Bring the Fun™",
  tagline1: "Here's what we're cooking.",
  tagline2: "Check it out!",
};

const Home = () => {
  const { site } = useParams();

  const [headerInfo, setHeaderInfo] = useState(defaultHeaderInfo);
  const [blogList, setBlogList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    async function fetchBlog() {
      try {
        const listModule = await import(`../../config/${site}/data`);
        const { headerInfo, blogList } = listModule;

        setBlogList(blogList);
        setBlogs(blogList);
        if (headerInfo) {
          setHeaderInfo(headerInfo);
        }
      } catch (error) {
        setBlogList([]);
        setBlogs([]);
        setHeaderInfo(defaultHeaderInfo);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    }

    fetchBlog();
  }, [site]);

  const [blogs, setBlogs] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  // Search submit
  const handleSearchBar = (e) => {
    e.preventDefault();
    handleSearchResults();
  };

  // Search for blog by category
  const handleSearchResults = () => {
    const allBlogs = blogList;
    const searchKeyLower = searchKey.toLowerCase().trim();
    const filteredBlogs = allBlogs.filter(
      (blog) =>
        blog.category.toLowerCase().includes(searchKeyLower) ||
        blog.title.toLowerCase().includes(searchKeyLower) ||
        blog.description.toLowerCase().includes(searchKeyLower)
    );
    setBlogs(filteredBlogs);
  };

  // Clear search and show all blogs
  const handleClearSearch = () => {
    setBlogs(blogList);
    setSearchKey("");
  };

  return (
    <div>
      {site ? (
        <Link className="blog-goBack" to="/">
          <span> &#8592;</span> <span>Go Back</span>
        </Link>
      ) : (
        <Link className="blog-goBack" to="/">
          <span>🍴</span>
        </Link>
      )}

      {/* Page Header */}
      <Header headerInfo={headerInfo} />

      {site ? (
        <>
          <SearchBar
            value={searchKey}
            clearSearch={handleClearSearch}
            formSubmit={handleSearchBar}
            handleSearchKey={(e) => setSearchKey(e.target.value)}
          />
          {loading ? null : !blogs.length ? (
            <EmptyList />
          ) : (
            <BlogList blogs={blogs} />
          )}
        </>
      ) : (
        <div className="home-main-page">
          <p className="main-spacer-bottom">
            Welcome to Silverware Games, an indie game dev company:
            games, art, social media, we do it all on XBOX, PC, iOS,
            Android, and more.
          </p>
          <p className="main-spacer">
            Chow down on a listing of all our work from the last decade right up
            to today!
          </p>
          <center>
            <h2 className="main-spacer">
              New Spoonful of Bloggin'
            </h2>
          </center>
          <BlogItem key={recentPost.id} blog={recentPost} index={0} />
          <center>
            <h2 className="main-spacer-top">
              More archived helpings:
            </h2>
          </center>
          <LinkList links={links} />
        </div>
      )}
    </div>
  );
};

export default Home;
