import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Blog from "./pages/Blog";
import Home from "./pages/Home";
import Image from "./pages/Image";
import Footer from "./components/common/Footer";

const App = () => {
  const [hitCount, setHitCount] = useState(0);
  const location = useLocation();

  const fetchHitCount = async () => {
    const baseUrl = window.location.hostname === "localhost" ? "http://localhost:3001" : "https://silverwaregames.io";
    try {
      const response = await fetch(`${baseUrl}/track`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      setHitCount(data.hits);
    } catch (error) {
      console.error("Error fetching hit count:", error);
    }
  };

  useEffect(() => {
    fetchHitCount();
  }, [location.pathname]); // Fetch hit count on every route change

  return (
    <div className="container">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:site" element={<Home />} />
        <Route path="/:site/:id" element={<Blog />} />
        <Route path="/:site/:id/:index" element={<Image />} />
      </Routes>
      <Footer hitCount={hitCount} />
    </div>
  );
};

export default App;
