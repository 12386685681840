import React from "react";
import "./styles.css";

const Header = ({ headerInfo }) => (
  <header className="home-header">
    <h2>{headerInfo.top}</h2>
    <h1>
      <span>“</span>
      {headerInfo.title}
      <span>”</span>
    </h1>
    <p>
      {headerInfo.tagline1}
      <br />
      {headerInfo.tagline2}
    </p>
  </header>
);

export default Header;
